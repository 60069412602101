import React, { Fragment } from "react";
import "./TitleHeader.scss";
import creditCardLogo from "./credit-cards-logo.png";

export const TitleHeader = () => (
  <Fragment>
    <img src={creditCardLogo} alt="Credit card logo" className="title-icon" />
    <h2 className="title-text">Confirm details to allow access to accounts</h2>
  </Fragment>
);
